/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleUtilsService } from '@dmx-fad-ui/fad/services';

@Component({
  selector: 'atlas-provider-star-rating',
  templateUrl: './provider-star-rating.component.html',
  styleUrls: ['./provider-star-rating.component.scss'],
})
export class ProviderStarRatingComponent implements OnInit {
  @Input() ratingCount: any;
  @Input() ratingValue: any;
  @Input() profileName: string;
  commentCount: number = 0;

  checkZeroString: number;
  constructor(private readonly simpleUtilsService: SimpleUtilsService,
              private router: Router
  ) {}

  ngOnInit(): void {
    if (this.ratingValue) {
      this.checkZeroString = parseFloat(this.ratingValue); 
      this.ratingValue = this.formatValue(this.ratingValue)
    }

    if(this.ratingCount){
      this.ratingCount = this.formatValue(this.ratingCount);
    }
  }

  isCommentsClick(isClicked: boolean) {
   this.simpleUtilsService.isShowProviderReviewsTab.next(isClicked);
   this.router.navigate(['/' + this.profileName]);
  }

  formatValue(value: string): string {
    return parseFloat(value).toString();
  }
}
