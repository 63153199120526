/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { FadProviderDetailResponse } from '@dmx-fad-ui/fad/data-access';
import { environment } from '../../../../environments/environment';
import { LocationService } from '../../services/location/location-service';
import { StyleObservablesService } from '../../services/style-observables/style-observables.service';
import { CommentsAndRatings } from './provider-ratings-comments/commentsAndRatings';
import { SimpleUtilsService } from '@dmx-fad-ui/fad/services';

@Component({
  selector: 'atlas-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss'],
})
export class ProviderComponent implements OnInit {
  @Input() providerDetails: FadProviderDetailResponse;

  @Input() providerName: string;
  commentsAndRatings: CommentsAndRatings;
  mapUrl: string;
  providerDetailsResults;
  providerExpertiseResults: any;
  providerLanguageResults: any;
  hasData: boolean = false;
  bio: string;
  youTubeVideoBioUrl: string;
  providerLocations: Array<string>;
  pubMedLinkUrl: string;
  boards: Array<string>;
  fellowships: Array<string>;
  jobTitles: Array<string>;
  residencies: Array<string>;
  schools: Array<string>;
  npi: string;
  visitTypes: string[];
  lgbtqAligned: boolean = false;
  referralsOn: boolean;
  showReferral: boolean = false;
  showReview: boolean = false;
  defaultMapHeight: string = '410';
  referralMapHeight: string = '240';
  mapHeight: string;
  displayName: string;
  videoFilterEnabled: boolean;
  checkProviderBio: boolean = true;
  isNewproviderProfileExperience = environment.isTabProviderPageEnabled;
  selectedTabindex: number = 0;

  constructor(
    private locationService: LocationService,
    private simpleUtilsService: SimpleUtilsService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.locationService = locationService;
    this.referralsOn = environment.referralsOn;
    this.mapHeight = this.defaultMapHeight;
    this.videoFilterEnabled = environment.videoFilterEnabled;
  }

  ngOnInit(): void {
    if (this.providerDetails) {
      this.providerDetailsResults = this.providerDetails.payload.providerDetail;
      this.mapUrl = this.providerDetailsResults.mapURL;
      this.providerExpertiseResults = this.providerDetailsResults.conditions;
      this.providerLanguageResults = this.providerDetailsResults.languages;
      this.bio = this.providerDetailsResults.bio;

      if (this.bio === '<p></p>') {
        this.bio = '';
      }

      this.youTubeVideoBioUrl = this.providerDetailsResults.youTubeVideoBioUrl;

      if (
        this.bio == '' &&
        this.youTubeVideoBioUrl == '' &&
        this.providerLanguageResults.length == 0
      ) {
        this.checkProviderBio = false;
      }

      this.commentsAndRatings = this.providerDetails.payload.providerDetail.commentsAndRatings;
      this.pubMedLinkUrl = this.providerDetailsResults.pubMedLinkUrl;

      if (this.providerDetailsResults.locations) {
        this.providerLocations = this.getLocationResults(this.providerDetailsResults.locations);
      }

      this.lgbtqAligned = JSON.parse(this.providerDetailsResults.lgbtqAligned);
      this.boards = this.providerDetailsResults.boards;

      this.fellowships = this.providerDetailsResults.fellowships;

      this.jobTitles = this.providerDetailsResults.jobTitles;

      if (this.providerDetailsResults.reviewRatingCount != 0) {
        this.showReview = true;
      }

      this.residencies = this.providerDetailsResults.residencies;

      this.schools = this.providerDetailsResults.schools;
      this.npi = this.providerDetailsResults.npi;
      this.visitTypes = this.providerDetailsResults.visitTypes;
      this.hasData = true;
      this.showReferral = this.parseTitles(
        this.providerDetailsResults.displayName,
        this.providerDetailsResults.outPatientEnabled,
      );
      if (this.showReferral && !this.isNewproviderProfileExperience) {
        this.mapHeight = this.referralMapHeight;
      }
      this.displayName = this.providerDetailsResults.displayName;
    }

    this.simpleUtilsService.isShowProviderReviewsTab.subscribe(tabValue => {
      if(tabValue){
        this.selectedTabindex = 2;
      }else{
        this.selectedTabindex = 0;
      }
    })
  }


  parseTitles(displayName: string, outpatientEnabled: string): boolean {
    // Necessary because outpatient enabled comes back as a string 'false'

    if (displayName.includes(', MD') || displayName.includes(', DO')) {
      if (outpatientEnabled !== 'false') {
        return true;
      }
    }

    return false;
  }

  getLocationResults(locationResultsArray) {
    const locations: Array<any> = [];
    const pracBuildings = locationResultsArray;
    pracBuildings.forEach((item) => {
      const providerPracticeBuildingObj =
        this.locationService.transformPracticeBuildingStringToObject(item);
      locations.push(providerPracticeBuildingObj);
    });
    return locations;
  }

  isBrowser() {
    return (
      isPlatformBrowser(this.platformId) &&
      'google' in window &&
      typeof google === 'object' &&
      typeof google.maps === 'object'
    );
  }
}
