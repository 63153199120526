<div class="star-rating-container">
  <span *ngIf="checkZeroString !== 0">
    <span class="ratings-value">{{ ratingValue }}</span>
    <span class="material-icons-outlined">
      {{ ratingValue | starRating: ratingValue }}
    </span>
    <span (click)="isCommentsClick(true)"  class="ratings-value provider-ratings-count">
      <span>(</span>
      <span class="rating-number">{{ ratingCount }}</span>
      <span>)</span>
    </span>
  </span>

  <span *ngIf="commentCount !== 0">
    <span class="comments-spacing"></span>
    <span class="pratings-value">Comments</span>
    <a class="provider-comments" (click)="isCommentsClick(true)" title="See Comments">
      {{ '(' + commentCount + ')' }}</a
    >
  </span>
</div>
